import axios from "axios";
import env from "../configs";
import { MEETING_BASE } from "./constants";

const API_BASE_URL = env?.API_BASE_URL;
const validMeetingID = async ({ meetingID }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}${MEETING_BASE}/validate/${meetingID}`, {});
    return data.DATA;
  } catch (err) {
    return err;
  }
};
const checkJoiningAbility = async ({ meetingID }) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}${MEETING_BASE}/${meetingID}`, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    });

    return data.DATA;
  } catch (err) {
    return err;
  }
};
const meetingService = {
  checkJoiningAbility,
  validMeetingID
};

export default meetingService;
