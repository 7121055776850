import axios from "axios";
import env from "../configs";
import { store } from "../store/store";
import { CHECK_LOGIN_STATUS, LOG_OUT, ZUM_USER_DATA } from "./constants";
import * as meActions from "../store/actions/meActions";
import * as settingsActions from "../store/actions/settingsActions";

const API_BASE_URL = env?.API_BASE_URL;

axios.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0"
};

const logout = () => {
  return axios
    .get(API_BASE_URL + LOG_OUT)
    .then((response) => {
      if (response) {
        localStorage.removeItem(ZUM_USER_DATA);
      }
    })
    .catch((err) => {
      console.log("logout err", err);
      localStorage.removeItem(ZUM_USER_DATA);
    });
};

const checkLoginStatus = async () => {
  const config = {
    url: `${API_BASE_URL}${CHECK_LOGIN_STATUS}`,
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true
    }
  };
  try {
    const { data: response } = await axios(config);
    const userInfo = response.DATA;
    if (response.STATUS === "SUCCESS" && userInfo?.loggedIn) {
      store.dispatch(meActions.loggedIn(true));
      store.dispatch(meActions.setUserInfo(userInfo));
      const displayName = `${userInfo?.name.firstName} ${userInfo?.name?.lastName}`;
      store.dispatch(settingsActions.setDisplayName(displayName));
    }
  } catch (err) {
    console.log("check login status error", err?.message);
  }
};
const authService = {
  logout,
  checkLoginStatus
};

export default authService;
