export const ZUM_USER_DATA = "ZUM_USER_DATA";
export const RESPONSE_SUCCESS = "SUCCESS";
export const RESPONSE_ERROR = "FAILURE";

export const CHECK_LOGIN_STATUS = "auth/check-login-status";
export const LOGIN = "auth/login";
export const REGISTER = "auth/local/register";
export const LOG_OUT = "auth/logout";
export const VERIFY_AUTH = "auth/local/verify";

export const MEETING_BASE = "meetings";
